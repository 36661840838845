.dialogue {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.dialogue-internal {
  position: relative;
  border-radius: 4px;
  transition: all 250ms ease;
  box-shadow: 0px 3px 5px var(--contrast);
}

.dialogue-content {
  max-width: min(600px, 95vw);
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  color: var(--contrast);
  padding: 2rem;
  gap: 1rem;
  z-index: 2;
  border-radius: inherit;
}

.dialogue-section {
  display: flex;
  width: 100%;
}

.dialogue-section.col {
  flex-direction: column;
}

.dialogue-controls {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.fade-in {
  animation: fadeIn var(--fade-duration) ease forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut var(--fade-duration) ease forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.promo-title {
  font-weight: bold;
  text-align: center;
  justify-content: center;
}

.promo-options {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.promo-option {
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 2rem;
  cursor: pointer;
  background-color: var(--background);
  color: var(--contrast);
  transition: all 200ms ease;
  border: 3px solid var(--contrast);
  box-shadow: none;
  flex-shrink: 0;
}

.promo-option.small {
  transform: scale(0.75);
}

.promo-option.small:hover {
  transform: scale(0.85);
}

.promo-option.inverted {
  background-color: var(--contrast);
  color: var(--background);
}

.promo-option:not(.no-effects):hover {
  background-color: var(--contrast);
  color: var(--background);
  transform: translate(-2px, -2px);
  box-shadow: 1px 1px 5px var(--contrast);
}

.promo-option:not(.no-effects):active {
  transform: translate(0px, 0px);
  box-shadow: none;
  background-color: var(--primary);
  border-color: var(--primary);
}

.join-game-content {
  min-width: 350px;
}

.side-selection {
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.searching-graphic {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  font-size: 2rem;
}

.searching-title {
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
}

.loading-rotate {
  animation:
    LoadingRotate 4000ms var(--anim-delay) ease infinite,
    ColourCycle 12000ms calc(var(--anim-delay) + 1000ms) ease infinite;
}

@keyframes LoadingRotate {
  0% {
    transform: rotateY(0);
  }
  10% {
    transform: rotateY(180deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  60% {
    transform: rotateX(360deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}

.loading-colour-cycle {
  animation: ColourCycle 8000ms ease infinite;
  animation-delay: var(--anim-delay);
}

@keyframes ColourCycle {
  0% {
    color: var(--contrast);
  }
  10% {
    color: red;
  }
  15% {
    color: var(--contrast);
  }
  20% {
    color: orange;
  }
  25% {
    color: var(--contrast);
  }
  30% {
    color: yellow;
  }
  35% {
    color: var(--contrast);
  }
  40% {
    color: yellowgreen;
  }
  45% {
    color: var(--contrast);
  }
  50% {
    color: green;
  }
  55% {
    color: var(--contrast);
  }
  60% {
    color: blue;
  }
  65% {
    color: var(--contrast);
  }
  70% {
    color: indigo;
  }
  75% {
    color: var(--contrast);
  }
  80% {
    color: violet;
  }
  85% {
    color: var(--contrast);
  }
  90% {
    color: var(--background);
  }
  100% {
    color: var(--contrast);
  }
}
