.alert-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  pointer-events: none;
  overflow: hidden;
}

.alert-relative {
  position: relative;
}

.alert {
  position: absolute;
  pointer-events: all;
  display: flex;
  align-items: center;
  background-color: var(--background);
  bottom: -400px;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: 0px 3px 5px var(--contrast);
  min-width: 350px;
  padding: 0.5rem;
  gap: 0.5rem;
  background-color: var(--alert-primary);
}

.alert-content {
  padding: 0.5rem;
  flex: 1;
}

.slide-up {
  animation: SlideUp var(--alert-transition-time) ease 0s 1 forwards;
}

@keyframes SlideUp {
  0% {
    bottom: -200px;
  }
  50% {
    bottom: 2.5rem;
  }
  75% {
    bottom: 1.75rem;
  }
  90% {
    bottom: 2.2rem;
  }
  100% {
    bottom: 2rem;
  }
}

.slide-down {
  animation: SlideDown var(--alert-transition-time) ease 0s 1 forwards;
}

@keyframes SlideDown {
  0% {
    bottom: 2rem;
  }
  100% {
    bottom: -200px;
  }
}

.alert-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  border: 2px solid var(--contrast);
  color: var(--contrast);
  margin-right: 0.5rem;
  cursor: pointer;
  transition: all 100ms ease;
  opacity: 0.75;
}

.alert-close-button:hover {
  color: var(--alert-primary);
  background-color: var(--contrast);
  opacity: 0.5;
}
