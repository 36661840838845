* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* COLOURS */
  --background: #eee;
  --background-dark: #4f4f4f;
  --contrast: #222;
  --offset: #aaa;
  --primary-light: #f0e68c;
  --primary-dark: #8b8000;
  --primary: yellow;
  --button-primary: var(--contrast);
  /* OTHER */
  --menu-height: 50px;
  --anim-delay: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input {
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
}

.nodisplay {
  display: none !important;
}

.no-interact,
.no-interact > * {
  cursor: not-allowed;
}

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-dark);
  /* justify-content: center; */
}

.game-canvas {
  display: flex;
  position: relative;
}

.board {
  position: relative;
  width: 0;
  height: 0;
}

.tile {
  position: absolute;
  color: white;
}

.relative-parent {
  position: relative;
  width: 100%;
  height: 100%;
}

.hex {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 200ms ease;
  z-index: 1;
}

.hex > * {
  z-index: 1;
}

.hex-left {
  height: 100%;
  width: 0;
  border-right: calc(var(--hex-size) / 2) solid var(--hex-colour);
  border-top: calc(var(--hex-h) / 2) solid transparent;
  border-bottom: calc(var(--hex-h) / 2) solid transparent;
  transition: inherit;
}

.hex-mid {
  height: 100%;
  width: var(--hex-size);
  background-color: var(--hex-colour);
  transition: inherit;
}

.hex-right {
  height: 100%;
  width: 0;
  border-left: calc(var(--hex-size) / 2) solid var(--hex-colour);
  border-top: calc(var(--hex-h) / 2) solid transparent;
  border-bottom: calc(var(--hex-h) / 2) solid transparent;
  transition: inherit;
}

.hex-shadow {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  bottom: calc(50% - 20px);
  left: calc(50% - 20px);
  box-shadow: 0px 0px 40px black;
  z-index: 0;
}

.piece-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  font-size: calc(var(--hex-size) * 3 / 4);
  z-index: 10;
}

.piece-silhouette-container {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  font-size: calc(var(--hex-size) * 3 / 4);
  z-index: 11;
  color: var(--primary-dark);
  text-align: center;
}

.kill {
  color: darkred;
}

.piece-wrapper {
  width: 60%;
  aspect-ratio: 1 / 1;
  border-radius: 1000px;
  border: 3px solid var(--piece-contrast);
  background-color: var(--piece-color);
  color: var(--piece-contrast);
  cursor: pointer;
  transition: all 100ms ease;
  text-align: center;
}

.menu {
  height: var(--menu-height);
  width: 100%;
  background-color: var(--contrast);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--background);
  box-shadow: 0px 0px 5px black;
}

.menu-op {
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem;
  --element-border: 1px solid transparent;
  border-right: var(--element-border);
  transition: all 200ms ease;
}

.menu-op.small {
  font-size: 0.8rem;
}

.menu-op:hover {
  background-color: #444;
}

.menu-op:active {
  background-color: var(--offset);
}

.menu-op:first-of-type {
  border-left: var(--element-border);
}

.menu-subtitle {
  font-size: 0.75rem;
}

.turn-counter-container {
  position: absolute;
  left: 0;
  bottom: 0;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  color: var(--background);
  /* padding: 1rem; */
}
